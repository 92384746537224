import React from 'react';
import styled from 'styled-components';
import { pageMargins } from '../../shared/styles';
import CentrelandLogoColor from '../../images/svgs/CentrelandLogoColor';
import NavBurger from './NavBurger';

const StyledHeader = styled.div`
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: transparent;
  position: absolute;
  left: 0;
  top: 0;
  ${pageMargins}
`;

const Header = ({ toggleSidebar, sidebarIsOpen }) => {
  return (
    <StyledHeader>
      <div />
      <NavBurger
        className="header__burger"
        toggleSidebar={toggleSidebar}
        sidebarIsOpen={sidebarIsOpen}
      />
    </StyledHeader>
  );
};

export default Header;
