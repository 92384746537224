import React from 'react';
import { Link } from 'gatsby';
import parse from 'html-react-parser';
import StyledHero from './StyledHero';
import ArrowDownOrange from '../../images/svgs/ArrowDownOrange';
import ArrowBack from '../../images/svgs/ArrowGallery';
import CentrelandLogoColor from '../../images/svgs/CentrelandLogoColor';
import Video from '../globals/Video';

const HeroSection = ({ bg, header, subheader, page, contact, bgVideo, goBack }) => {
  return (
    <StyledHero
      className="hero-section"
      bg={bg}
      bgVideo={bgVideo}
      project={!!subheader}
      page={page}
      contact={contact}
    >
      <div className="content__container">
        <Link to="/">
          <CentrelandLogoColor className="header__logo" />
        </Link>
        {goBack ? (
          <button className="back-button" onClick={goBack}>
            <ArrowBack direction="left" />
            <p>Back</p>
          </button>
        ) : (
          ''
        )}
        {parse(header)}
        {subheader ? parse(subheader) : ''}
        {!subheader ? <ArrowDownOrange className="arrow__orange" /> : ''}
      </div>
      {bgVideo && <Video src={bgVideo}></Video>}
    </StyledHero>
  );
};

export default HeroSection;
