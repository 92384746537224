import React from 'react';

const paths = {
  left: 'M10.136 20.27l1.727-1.726-7.189-7.188h32.463V8.914H4.674l7.189-7.189L10.136 0 0 10.135z',
  right: 'M27.001 0l-1.726 1.727 7.188 7.188H0v2.442h32.463l-7.188 7.188L27 20.271l10.136-10.135z',
};

function ArrowGallery(props) {
  return (
    <svg width={38} height={21} viewBox="0 0 38 21" {...props}>
      <path fill="#000" fillRule="evenodd" d={paths[props.direction]}></path>
    </svg>
  );
}

export default ArrowGallery;
