import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={64} height={101} viewBox="0 0 64 101" {...props}>
      <title>{'Logomark'}</title>
      <g fill="#F2F2F2" stroke="#283645" strokeWidth={2} fillRule="evenodd">
        <path d="M33 35.013V3L2 35.013 17.495 51zM33 98l30-32.48V33L33 66z" />
        <path d="M33 66L2 35.012V66L33 98z" />
      </g>
    </svg>
  );
}

export default SvgComponent;
