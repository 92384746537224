import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { color } from '../../shared/styles.js';

const lineTransitionTime = '0.3s';

const StyledBurgerMenu = styled.div`
  position: fixed;
  width: 2.8rem;
  height: 2.35rem;
  cursor: pointer;
  outline: none;
  z-index: 21;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  &.has-scrolled {
    background-color: ${color.darkest};
  }
  &.is-open {
    background-color: ${color.darkest};
  }
  & .burger-menu-lines {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 61%;
    height: 45%;
    display: grid;
    grid-auto-flow: row;
    gap: 0.3rem;
    transition-duration: ${lineTransitionTime};
    color: transparent;
    & .line {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;

      &:before,
      &:after {
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: white;

        transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1);
      }

      &:before {
        transform: scaleX(0);
        transform-origin: left;
      }

      &:after {
        transform-origin: right;
        transition-delay: 0.25s;
      }

      &:nth-child(1) {
        &:before {
          transition-delay: ${`${0.1 * 0}s`};
        }

        &:after {
          transition-delay: ${`${0.1 + 0.25 * 0}s`};
        }
      }
      &:nth-child(2) {
        &:before {
          transition-delay: ${`${0.1 * 1}s`};
        }

        &:after {
          transition-delay: ${`${0.1 + 0.25 * 1}s`};
        }
      }
      &:nth-child(3) {
        &:before {
          transition-delay: ${`${0.1 * 2}s`};
        }

        &:after {
          transition-delay: ${`${0.1 + 0.25 * 2}s`};
        }
      }
    }

    &:hover {
      & .line:before {
        transform: scaleX(1);
        transition-delay: 0.25s;
      }

      & .line:after {
        transform: scaleX(0);
        transition-delay: 0s;
      }
      & .line {
        &:nth-child(1) {
          &:before {
            transition-delay: ${`${0.1 + 0.25 * 0}s`};
          }

          &:after {
            transition-delay: ${`${0.1 * 0}s`};
          }
        }
        &:nth-child(2) {
          &:before {
            transition-delay: ${`${0.1 + 0.25 * 1}s`};
          }

          &:after {
            transition-delay: ${`${0.1 * 1}s`};
          }
        }
        &:nth-child(3) {
          &:before {
            transition-delay: ${`${0.1 + 0.25 * 2}s`};
          }

          &:after {
            transition-delay: ${`${0.1 * 2}s`};
          }
        }
      }
    }
  }

  .burger-menu-lines .line:nth-child(1) {
    top: 0px;
    transform-origin: left center;
  }

  .burger-menu-lines .line:nth-child(2) {
    top: 7px;
    transform-origin: left center;
  }

  .burger-menu-lines .line:nth-child(3) {
    top: 14px;
    transform-origin: left center;
  }

  .burger-menu-lines.is-open .line:nth-child(1) {
    transform: rotate(45deg);
    top: -2px;
    left: 4px;
  }

  .burger-menu-lines.is-open .line:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  .burger-menu-lines.is-open .line:nth-child(3) {
    transform: rotate(-45deg);
    top: 17px;
    left: 4px;
  }

  @media only screen and (max-width: 1024px) {
    display: inline-block;
  }
`;

const NavBurger = ({ sidebarIsOpen, toggleSidebar, scrolled }) => {
  return (
    <StyledBurgerMenu
      className={`burger-menu ${sidebarIsOpen ? 'is-open' : ''}`}
      sidebarIsOpen={sidebarIsOpen}
      onClick={toggleSidebar}
    >
      <div className={`burger-menu-lines ${sidebarIsOpen ? 'is-open' : ''}`}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </StyledBurgerMenu>
  );
};

export default NavBurger;
