import { css } from 'styled-components';

// Global style variables

export const color = {
  // Palette
  orange: '#e56d46',
  mainBg: '#E6E9ED', // coral
  footerBg: '#283645',
  overlay: 'rgba(0, 38, 74, 0.6)',
  // Monochrome
  grey: '#CACAC6',
  darker: '#2a2a28',
  darkest: '#1d1d1b',
};

export const typography = {
  type: {
    primary: '"Bodoni Std", "serif"',
    secondary: '"avenir", Sans-Serif',
  },
  weight: {
    regular: '400',
    bold: '700',
    extrabold: '800',
    black: '900',
  },
  size: {
    s1: '0.75rem',
    s2: '1rem',
    s3: '1.125rem',
    m1: '1.25rem',
    m2: '1.5rem',
    m3: '2.25rem',
    l1: '2.5rem',
    l2: '3rem',
    l3: '5rem',
  },
};

export const breakpoint = 600;
export const pageMargin = '5.55555';

export const pageMargins = css`
  padding-left: 30px;
  padding-right: 30px;

  @media (min-width: 640px) {
    //padding-left: ${pageMargin * 1}%;
    //padding-right: ${pageMargin * 1}%;
  }
  @media (min-width: 768px) {
    //padding-left: ${pageMargin * 2}%;
    //padding-right: ${pageMargin * 2}%;
  }
  @media (min-width: 1024px) {
    padding-left: ${pageMargin * 1.5}%;
    padding-right: ${pageMargin * 1.5}%;
  }
  @media (min-width: 1440px) {
    padding-left: ${pageMargin * 1.8}%;
    padding-right: ${pageMargin * 1.8}%;
  }
`;
