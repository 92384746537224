import React, { useRef, useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useTransition, useChain, animated, config } from 'react-spring';
import NavLink from './NavLink';
import ProjectNavLink from './ProjectNavLink';

import StyledSidebar from './StyledSidebar';

const Sidebar = React.forwardRef(({ show }, ref) => {
  const [showProjs, setShowProjs] = useState(false);
  const {
    allPrismicPageTemplate: { nodes: titles },
    allPrismicProjectTemplate: { nodes: projectTitles },
    prismicNavigation: {
      data: { nav_items: navItems },
    },
  } = useStaticQuery(graphql`
    {
      allPrismicPageTemplate {
        nodes {
          data {
            page_title {
              text
              html
            }
          }
          uid
        }
      }
      prismicNavigation {
        data {
          nav_items {
            nav_link {
              uid
              url
            }
            nav_title {
              text
              html
            }
          }
        }
      }
      allPrismicProjectTemplate {
        nodes {
          uid
          data {
            project_title {
              html
              text
            }
          }
        }
      }
    }
  `);
  const sidebarRef = useRef();
  const transition = useTransition(show, null, {
    from: {
      transform: 'translateX(100%)',
    },
    enter: {
      transform: 'translateX(0)',
    },
    leave: {
      transform: 'translateY(100%)',
    },
    unique: true,
    ref: sidebarRef,
  });

  const items = navItems.map(items => items.nav_title.text);
  const projectItems = [
    'All Projects',
    ...projectTitles.map(title => title.data.project_title.text),
  ];

  const links = navItems.map(items => items.nav_link.uid); //titles.map(title => title.uid);
  const projectLinks = ['projects', ...projectTitles.map(title => title.uid)];

  const itemsRef = useRef();
  const trail = useTransition(show ? items : [], item => item, {
    from: {
      opacity: 0,
      transform: 'translateY(40px)',
    },
    enter: {
      opacity: 1,
      transform: 'translateY(0)',
    },
    leave: {
      opacity: 0,
      transform: 'translateY(-25px)',
    },
    ref: itemsRef,
    trail: 50,
    unique: true,
  });

  const toggleProjectLinks = () => {
    setShowProjs(prevState => !prevState);
  };

  useChain(show ? [sidebarRef, itemsRef] : [itemsRef, sidebarRef], show ? [0, 0.25] : [0, 0.6]);

  useEffect(() => {
    if (!show) {
      setShowProjs(false);
    }
  }, [show]);

  return transition.map(({ item, key, props }) =>
    item ? (
      <StyledSidebar key={key} style={props} ref={ref} showProjs={showProjs}>
        {trail.map(({ item, key, props }, i) => (
          <animated.div key={item} style={props} className="sidebar__item">
            {item === 'Projects' ? (
              <ProjectNavLink
                links={projectLinks}
                titles={projectItems}
                showProjs={showProjs}
                toggleProjectLinks={toggleProjectLinks}
              />
            ) : (
              <NavLink link={links[i]} title={item} />
            )}
          </animated.div>
        ))}
      </StyledSidebar>
    ) : null,
  );
});

export default Sidebar;
