import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
import { typography } from '../../shared/styles';

const StyledContact = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  & h6,
  p {
    font-family: ${typography.type.secondary};
  }
  p {
    font-weight: ${typography.type.regular};
    line-height: 1.9rem;
    font-size: ${typography.size.s3};
  }
  & h6 {
    margin-top: 2rem;
    font-weight: ${typography.weight.bold};
    text-transform: uppercase;
    line-height: 1.4rem;
    font-size: ${typography.size.s1};
    letter-spacing: 0.1rem;
  }
  & * {
    color: ${({ form }) => (form ? 'black' : 'white')};
  }
  & .contact__section {
    margin: 0 4rem 0 0;
  }
  @media only screen and (max-width: 640px) {
    flex-direction: ${({ form }) => (form ? 'row' : 'column')};
    justify-content: flex-start;
    & .contact__section {
      margin-right: ${({ form }) => (form ? '1.5rem' : '0')};
      & p {
        font-size: 0.75rem;
        line-height: 18px;
      }
    }
    & .contact & p {
    }
    & h6 {
      margin-top: ${({ form }) => (form ? '0' : '1.5rem')};
    }
  }
`;

const Contact = ({ flexDirection, cms, form }) => {
  return (
    <StyledContact form={form} className="contact__information" flexDirection={flexDirection}>
      {cms.map(text => (
        <div className="contact__section">{parse(text.section_text.html)}</div>
      ))}
    </StyledContact>
  );
};

export default Contact;
