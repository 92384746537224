import React, { useRef } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { useTrail, animated, useChain, useTransition } from 'react-spring';

const config = { mass: 5, tension: 2000, friction: 200 };

const StyledDropDown = styled.div``;

const ProjectDropdown = ({ showProjs, links, titles }) => {
  const trail = useTrail(titles.length, {
    config,
    opacity: showProjs ? 1 : 0,
    x: showProjs ? 0 : 20,
    delay: showProjs ? 2 : 0,
    from: { opacity: 0, x: 20, height: 0 },
    height: showProjs ? 100 : 0,
  });

  return (
    <StyledDropDown titlesLength={titles.length} showProjs={showProjs}>
      {trail.map(({ x, height, ...rest }, index) => (
        <animated.div
          key={titles[index]}
          className="trails-text"
          style={{ ...rest, transform: x.interpolate(x => `translate3d(0,${x}px,0)`) }}
        >
          <Link
            to={`${links[index] !== 'projects' ? `/projects/${links[index]}` : `/${links[index]}`}`}
          >
            <h3 style={{ height }}>{titles[index]}</h3>
          </Link>
        </animated.div>
      ))}
    </StyledDropDown>
  );
};

export default ProjectDropdown;
