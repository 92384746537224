import React from 'react';
import { Link } from 'gatsby';
import ProjectDropDown from './ProjectDropdown';
import ArrowDownOrange from '../../../images/svgs/ArrowDownOrange';
const ProjectNavLink = ({ showProjs, toggleProjectLinks, titles, links }) => {
  return (
    <div>
      <h2 onClick={toggleProjectLinks} className="nav__link--projects">
        Projects <ArrowDownOrange className="nav__link--projects-svg" />
      </h2>
      {showProjs ? <ProjectDropDown titles={titles} links={links} showProjs={showProjs} /> : null}
    </div>
  );
};

export default ProjectNavLink;
