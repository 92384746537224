import styled from 'styled-components';
import { animated } from 'react-spring';
import { color, typography } from '../../../shared/styles.js';
const StyledSidebar = styled(animated.div)`
  height: 100vh;
  width: 30rem;
  z-index: 20;
  background-color: ${color.mainBg};
  padding-top: 7rem;
  position: absolute;
  right: 0;
  top: 0;
  position: fixed;
  & a {
    text-decoration: none;
    color: ${color.darkest};
  }

  & .sidebar__item {
    text-align: left;

    padding: 1rem 40px 0;
    cursor: pointer;

    font-weight: bold;
    letter-spacing: 1px;
    & h1,
    h2 {
      font-size: ${typography.size.m3};
      color: ${color.darkest};
      margin-bottom: ${({ showProjs }) => (showProjs ? '0.4rem' : '1.3rem')};
      transition: margin-bottom 0.2s ease;
    }

    &:first-child {
      margin-top: 0;
    }
  }
  h2.nav__link--projects {
    margin-bottom: ${props => (props.showProjs ? '1rem' : '1.5rem')};
    display: flex;
    & .nav__link--projects-svg {
      width: 1.8rem;
      margin-left: 1rem;
      transition: transform 0.3s ease;
      transform: ${props => `rotate(${props.showProjs ? '180deg' : 0})`};
    }
    @media only screen and (max-width: 640px) {
      align-items: center;
      & .nav__link--projects-svg {
        width: 1.3rem;
      }
    }
  }

  h3 {
    font-size: ${typography.size.s3};
    line-height: 2.5rem;
  }
  @media only screen and (max-width: 640px) {
    width: 100%;
    & .sidebar__item {
      & h2 {
        font-size: 1.5rem;
        margin-bottom: ${({ showProjs }) => (showProjs ? '0.3rem' : '1rem')};
      }
    }
    & h3 {
      font-size: 1rem;
    }
  }
`;

export default StyledSidebar;
