import styled, { createGlobalStyle, css } from 'styled-components';
import { color, typography, pageMargins } from './styles';
import reset from './reset';

import BodoniStdW1 from '../../static/fonts/BodoniStd-Book.woff2';
import BodoniStdW2 from '../../static/fonts/BodoniStd-Book.woff2';

const fontFaces = css`
  @font-face {
    font-family: 'Bodoni Std';
    src: url('${BodoniStdW2}') format('woff2'),
      url('${BodoniStdW1}') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
`;

export const bodyStyles = css`
  font-family: ${typography.type.primary};
  font-size: ${typography.size.s3};
  color: ${color.darkest};

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;

  * {
    box-sizing: border-box;
  }

  main {
    width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: ${typography.weight.regular};
    margin: 0;
    padding: 0;
  }

  h2 {
    margin-bottom: 2.8125rem;
  }

  button,
  input,
  textarea,
  select {
    outline: none;
    font-family: ${typography.type.primary};
  }

  sub,
  sup {
    font-size: 0.8em;
  }

  sub {
    bottom: -0.2em;
  }

  sup {
    top: -0.2em;
  }

  b,
  em {
    font-weight: ${typography.weight.bold};
  }

  hr {
    border: none;
    border-top: 1px solid ${color.darker};
    clear: both;
    margin-bottom: 1.25rem;
  }
`;

export const LayoutContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  position: relative;
  background-color: ${({ bgColor }) => bgColor};
  overflow: hidden;
`;

export const GlobalStyle = createGlobalStyle`
${reset}
${fontFaces}
 body {
   ${bodyStyles}
 }
`;
