import React from 'react';
import StyledVideo from './StyledVideo';

const Video = ({ src }) => {
  return (
    <StyledVideo autoPlay preload playsinline muted>
      <source src={src} type='video/mp4' />
    </StyledVideo>
  );
};

export default Video;
