import React from 'react';
import { Link } from 'gatsby';
const NavLink = ({ link, title }) => {
  return (
    <Link to={link === 'home' ? '/' : title === 'Contact' ? `/contact` : `/${link}`}>
      <h2>{title}</h2>
    </Link>
  );
};

export default NavLink;
