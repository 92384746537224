import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={60} height={33} viewBox="0 0 60 33" {...props}>
      <title>{'Path 3'}</title>
      <path
        d="M1.881 2.138l28.1 28.1L58.619 1.6"
        stroke="#E56D46"
        strokeWidth={3}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgComponent;
