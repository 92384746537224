import React from 'react';
import styled from 'styled-components';
import { pageMargins, color, typography } from '../../shared/styles';
import Contact from './Contact';
import CentrelandLogoBW from '../../images/svgs/CentrelandLogoBW';

const StyledFooter = styled.footer`
  width: 100%;
  ${pageMargins}
  background-color: ${color.footerBg};
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
  position: relative;

  & .footer__tncs {
    font-weight: ${typography.type.regular};
    line-height: 1.2rem;
    width: 30rem;
    font-size: 0.75rem;
    color: white;
    font-family: ${typography.type.secondary};
    margin-top: 2rem;
    @media only screen and (max-width: 640px) {
      width: 100%;
      padding-right: 2rem;
    }
  }
  & .footer__bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

const Footer = ({ cms }) => {
  return (
    <StyledFooter>
      <Contact flexDirection="row" cms={cms} />
      <div className="footer__bottom">
        <p className="footer__tncs">
          &copy; { new Date().getFullYear() } Centreland Limited. <br /> Registered office Bolsover House, 5/6 Clipstone St,
          Fitzrovia, London W1W 6BB
          Company registration number 06523920. Registered in England.
        </p>
        <CentrelandLogoBW className="footer__logo" />
      </div>
    </StyledFooter>
  );
};

export default Footer;
