import React, { Fragment, useState, useRef, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import throttle from 'lodash/throttle';
import { Footer, Header } from '../globals';
import { GlobalStyle, LayoutContainer } from '../../shared/global';
import Sidebar from '../globals/sidebar';
import '../../shared/swiper.css';

export default props => (
  <StaticQuery
    query={graphql`
      query SiteQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
        prismicContactInformation {
          data {
            contact_information_section {
              section_text {
                html
              }
            }
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

const Layout = props => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const sidebarRef = useRef(null);
  // Define the meta title and description
  const title = props.data.site.siteMetadata.title;
  const description = props.data.site.siteMetadata.description;
  const bgColor = props.bgColor ? props.bgColor : 'white';

  // Load the Prismic edit button
  if (typeof window !== 'undefined' && window.prismic) {
    window.prismic.setupEditButton();
  }
  const toggleSidebar = e => {
    setSidebarIsOpen(prevState => !prevState);
  };

  const scrollEvent = e => {
    const burger = document.querySelector('.burger-menu');
    const heroHeight = document.querySelector('.hero-section').offsetHeight;
    if (window.scrollY < heroHeight) {
      burger.classList.remove('has-scrolled');
    } else {
      burger.classList.add('has-scrolled');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', throttle(scrollEvent, 100));
  }, []);

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link
          href="https://fonts.googleapis.com/css?family=Lato:300,400,700,900"
          rel="stylesheet"
          type="text/css"
        ></link>
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        ></link>
      </Helmet>
      <GlobalStyle />
      <LayoutContainer bgColor={bgColor}>
        <Sidebar show={sidebarIsOpen} ref={sidebarRef} toggleSidebar={toggleSidebar} />
        <Header toggleSidebar={toggleSidebar} sidebarIsOpen={sidebarIsOpen} />
        <main>{props.children}</main>
        <Footer cms={props.data.prismicContactInformation.data.contact_information_section} />
      </LayoutContainer>
    </Fragment>
  );
};
