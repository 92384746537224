import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={191} height={84} viewBox="0 0 191 84" {...props}>
      <title>{'Logo Light'}</title>
      <g fill="none" fillRule="evenodd">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M171.498 20L178 13.3389V0L165 13.3389L171.498 20" fill="#91D1E8"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M178 26.5084V40L191 26.5084V13L178 26.5084" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M178 40V26.6664L171.498 20L165 26.6664L178 40" fill="#1A5690"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M165 26L172 19.5004L165 13V26" fill="#00A3DA"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22 57.4507V66.8652H21.4471C21.234 65.5619 20.7657 64.8897 20.0851 64.8897C19.4468 64.8897 18.7231 65.4783 17.6172 66.1923C16.2976 67.0749 14.9363 68 12.3831 68C9.27695 68 6.08468 66.4445 3.53146 63.5446C1.1059 60.7707 0 57.7872 0 54.089C0 49.8439 1.36122 45.9771 3.74448 43.2458C6.12773 40.5137 9.27695 39 12.5531 39C15.234 39 16.7659 40.0087 18.2978 40.892C19.1061 41.3538 19.7021 41.8581 20.2981 41.8581C21.021 41.8581 21.404 41.2702 21.4471 40.1773H22V48.4988H21.4471C20.8934 43.119 17.0635 39.5886 12.8507 39.5886C7.36129 39.5886 4.72346 44.338 4.72346 53.9204C4.72346 58.4594 5.36176 61.6533 6.2977 63.3767C7.70197 65.9401 10.1698 67.4114 12.4254 67.4114C15.1486 67.4114 18.0425 65.7723 19.9997 62.8298C20.9364 61.4436 21.2771 59.7203 21.4471 57.4507H22" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M35.5006 56.6813V54.6811C35.5006 51.9149 34.472 50.596 32.1576 50.596C29.9718 50.596 28.8999 52.1279 28.8999 55.2763V56.6813H35.5006ZM28.8999 62.213C28.8999 65.7021 30.143 67.2763 33.1862 67.2763C36.1435 67.2763 38.4578 65.2768 39.3579 61.787H40C38.9714 65.7444 36.3573 68 33.0143 68C28.7287 68 25 64.2125 25 59.1069C25 53.8298 28.3004 50 32.3288 50C36.1861 50 39.4005 52.9362 39.701 57.2766H28.8999V62.213V62.213Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M40 66.4193H42.3191V51.0783H40V50.4976H41.9529C43.6618 50.4976 44.9228 50.3319 45.7368 50V53.6904C47.2419 51.2027 49.0728 50 51.4735 50C54.647 50 56.6404 51.949 56.6404 55.1831V66.4193H59V67H50.9853V66.4193H53.2632V53.9804C53.2632 51.7834 52.8566 50.7883 51.0257 50.7883C48.9103 50.7883 47.2015 52.1146 45.7368 54.8519V66.4193H47.9743V67H40V66.4193" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M66.5478 67.1105C67.8727 67.1105 68.9167 66.2631 69.3985 64.7804H70C69.3985 66.8564 67.9532 68 65.9456 68C64.6208 68 63.4563 67.5338 62.6538 66.7293C61.8507 65.9247 61.7701 64.9924 61.7701 63.8909C61.7701 63.5946 61.7701 63.3405 61.7701 63.0442V51.2265H59V50.6333C62.1714 50.6333 63.7777 48.8964 64.34 45H64.8218V50.6333H69.2774V51.2265H64.8218V64.5269C64.8218 66.3052 65.223 67.1105 66.5478 67.1105" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M71 66.4193H73.3531V51.0783H71V50.4976H72.9817C74.7143 50.4976 75.9941 50.3319 76.8198 50V54.3124C77.9751 51.4102 79.419 50 81.359 50C82.9685 50 84 50.9127 84 52.1565C84 53.4004 83.3399 54.146 82.3494 54.146C81.359 54.146 80.4923 53.3585 80.4923 52.4053C80.4923 51.8658 80.781 51.3683 81.2762 50.7883C81.1114 50.7463 80.9465 50.7051 80.781 50.7051C79.296 50.7051 77.6447 52.8197 76.8198 56.717V66.4193H79.0894V67H71V66.4193" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M95.5006 56.6813V54.6811C95.5006 51.9149 94.472 50.596 92.1576 50.596C89.9711 50.596 88.9006 52.1279 88.9006 55.2763V56.6813H95.5006ZM88.9006 62.213C88.9006 65.7021 90.143 67.2763 93.1862 67.2763C96.1435 67.2763 98.4578 65.2768 99.3579 61.787H100C98.9714 65.7444 96.3573 68 93.015 68C88.7287 68 85 64.2125 85 59.1069C85 53.8298 88.3004 50 92.3288 50C96.1861 50 99.4005 52.9362 99.7002 57.2766H88.9006V62.213V62.213Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M101 66.4079H103.327V39.6066H101V39.0146H102.959C104.347 39.0146 105.531 38.7609 106.755 38V66.4079H109V67H101V66.4079" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M120.766 58.6165C120.298 58.5733 119.872 58.5733 119.489 58.5733C115.574 58.5733 113.702 60.0665 113.702 63.2655C113.702 65.6967 114.808 67.0619 116.681 67.0619C118.297 67.0619 119.914 66.0806 120.766 64.374V58.6165ZM124.34 67.872C122.553 67.872 121.319 66.8908 120.936 65.1849C119.83 67.0619 117.83 68 114.893 68C112.043 68 110 66.123 110 63.7349C110 60.1513 113.659 57.9767 120.553 57.9767C120.638 57.9767 120.68 57.9767 120.766 57.9767V53.6684C120.766 51.5787 119.872 50.5974 117.489 50.5974C115.192 50.5974 113.83 52.0481 113.83 52.6872C113.83 53.0286 115.021 53.3701 115.021 54.5642C115.021 55.8006 114.298 56.5691 113.191 56.5691C112 56.5691 111.234 55.7582 111.234 54.5642C111.234 52.1753 113.745 50 117.574 50C119.447 50 120.979 50.5118 122.341 51.4931C123.957 52.6872 124.085 53.6684 124.085 55.5878V64.1612C124.085 65.995 124.51 66.8484 125.361 66.8484C126.127 66.8484 126.723 66.2934 127.532 65.2273L128 65.6536C126.808 67.1467 125.744 67.872 124.34 67.872V67.872Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M128 66.4193H130.318V51.0783H128V50.4976H129.953C131.661 50.4976 132.922 50.3319 133.737 50V53.6904C135.242 51.2027 137.073 50 139.474 50C142.646 50 144.64 51.949 144.64 55.1831V66.4193H147V67H138.985V66.4193H141.263V53.9804C141.263 51.7834 140.857 50.7883 139.026 50.7883C136.91 50.7883 135.201 52.1146 133.737 54.8519V66.4193H135.974V67H128V66.4193" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M155.709 66.3768C158.125 66.3768 159.958 64.9644 161.083 62.2636V54.8265C160 51.8772 158.042 50.2568 155.791 50.2568C153.125 50.2568 151.791 52.1264 151.791 55.8236V60.602C151.791 64.5485 152.834 66.3768 155.709 66.3768ZM155.916 67C151.333 67 148 63.5101 148 58.1918C148 53.2895 151.625 49.509 155.541 49.509C157.916 49.509 159.916 50.838 161.083 53.3315V39.579H158.709V38.9971H160.709C162.125 38.9971 163.334 38.7065 164.584 38V65.9195H167V66.5014H161.125V63.7593C159.709 65.9195 158.042 67 155.916 67V67Z" fill="white"/>
      </g>
    </svg>
  );
}

export default SvgComponent;
