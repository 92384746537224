import styled, { css } from 'styled-components';
import { typography, pageMargins, color } from '../../shared/styles';
import { bounce } from '../../shared/animation';

const normalPara = css`
  font-size: 1.5rem;
  color: #e56d46;
  margin-bottom: 4rem;
`;

const contactPara = css`
  font-size: 1.5rem;
  color: white;
  text-decoration: none;
  width: 30rem;
  text-align: center;
`;

const StyledHero = styled.div`
  z-index: 1;
  height: ${({ project, page }) => (!!project || page ? '36.875rem' : '100vh')};
  background: ${({ bg }) => (bg ? `url(${bg})` : color.footerBg)};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  position: relative;
  ${pageMargins}

  & .back-button {
    background: none;
    border: none;
    outline: none;
    display: flex;
    cursor: pointer;
    justify-content: center;
    height: 2rem;
    margin-bottom: 3rem;
    & p {
      color: white;
      font-size: 1.2rem;
      margin-left: 0.7rem;
    }
    & svg {
      width: 1.3rem;
      margin-bottom: 0.3rem;
      transition: transform 0.3s ease;
      & * {
        fill: white;
      }
    }
    &:hover {
      & svg {
        transform: translateX(-5px);
      }
    }
  }

  & .header__logo {
    position: absolute;
    top: 0.5rem;
    left: 0;
  }
  & .content__container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: ${({ project, contact }) => (!!project && !contact ? 'flex-start' : 'center')};
    justify-content: ${({ project, contact }) => (!!project && !contact ? 'flex-end' : 'center')};
    flex-direction: column;
    z-index: 300;
  }

  & h1 {
    font-size: ${({ project }) => (project ? typography.size.l2 : typography.size.l3)};
    color: white;
    margin-bottom: 1rem;
    //width: ${({ project, contact }) => (!!project && !contact ? '100%' : '25rem')};
    text-align: ${({ project, contact }) => (!!project && !contact ? 'left' : 'center')};
  }
  & h2 {
    font-size: ${typography.size.m1};
    font-family: ${typography.type.secondary};
    letter-spacing: 0.2rem;
    color: white;
    margin-bottom: 1.6875rem;
  }
  & p {
    ${({ contact }) => (contact ? `${contactPara}` : `${normalPara}`)};
  }
  & .arrow__orange {
    position: absolute;
    bottom: 5vh;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ${bounce} 2s cubic-bezier(0.6, 0.25, 0.26, 0.93) infinite;
    & path {
      height: 100%;
    }
    @media only screen and (max-width: 640px) {
      width: 2rem;
    }
  }
  & video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }

  @media only screen and (max-width: 640px) {
    height: 460px;
    & .header__logo {
      width: 26vw;
      max-width: 191px;
    }
    h1 {
      font-size: ${typography.size.l1};
      width: 100%;
    }
    h2 {
      font-size: ${typography.size.s2};
    }

    p {
      margin-bottom: 2rem;
      font-size: ${typography.size.m1};
    }
  }
`;

export default StyledHero;
